import { AGENTS_INFORMATIONS } from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/config/agents'

import * as S from './RowPrettoGalaxie.styles'

export type RowPrettoGalaxieProps = {
  buttonLabel?: string
  buttonUrl?: string
  googleAgentsMapId: string
  googleApiKey: string
  isButtonDisplayed: boolean
  isColored: boolean
  isFirstRowWithPromises: boolean
  suptitle?: string
  text: string
  title: string
}

const PRETTO_GALAXIE_LOGO = 'v1728395659/reveal/static-asssets/logo/logo.png'
const filteredAgents = AGENTS_INFORMATIONS.filter(agent => !agent.hideOnPretto)

export const RowPrettoGalaxie: React.FC<RowPrettoGalaxieProps> = ({
  googleAgentsMapId,
  buttonLabel,
  buttonUrl,
  googleApiKey,
  isButtonDisplayed,
  isColored = false,
  isFirstRowWithPromises = false,
  suptitle,
  text,
  title,
  ...props
}) => (
  <S.NewBlocRow isColored={isColored} isFirstRowWithPromises={isFirstRowWithPromises} {...props}>
    <S.TextSection>
      {suptitle && <S.Suptitle>{suptitle}</S.Suptitle>}

      <S.Title>{title}</S.Title>

      <S.Description allowedTypes={['a', 'strong']}>{text}</S.Description>

      <S.Image path={PRETTO_GALAXIE_LOGO} />

      {isButtonDisplayed && buttonLabel && buttonUrl && <S.Button>{buttonLabel}</S.Button>}
    </S.TextSection>

    <S.MapSection>
      <S.Map agents={filteredAgents} googleApiKey={googleApiKey} googleAgentsMapId={googleAgentsMapId} />
    </S.MapSection>
  </S.NewBlocRow>
)
